import React, { useEffect } from "react";
import Sidebar from "parts/Sidebar";
import Header from "parts/Header";
import ReactLoading from 'react-loading';
import ReviewForm from "parts/ReviewForm";
import { useLocation, useNavigate } from "react-router-dom";
import reviews from "constants/api/reviews";
import { useDispatch, useSelector } from "react-redux";
import { populateReview, statusReview } from "store/actions/reviews";
import useForm from "helpers/hooks/useForm";
import { validate as isValidUUID } from 'uuid';

export default function Review() {
  const location = useLocation();
  const dispatch = useDispatch();
  const REVIEW = useSelector((state) => state.reviews);
  const params = location.pathname.split('/');
  const navigate = useNavigate();
  let match = {};

  const [state, setKey, setState] = useForm({
    note: "",
    rating: 5,
    is_exist: false,
    review_id: -1,
    course_id: "",
    course_name: "",
  });

  // Check params
  if (!isValidUUID(params[2])){
    match = {
      params: {
        courseID: null
      }
    }

    // Navigate to review list
    navigate("/reviews");
  } else {
    match = {
      params: {
        courseID: params[2]
      }
    }
  }

  useEffect(() => {
    // Scroll to top
    window.scroll(0, 0);

    // Check is update or create new review
    if (match.params.courseID){
      // Show loader
      dispatch(statusReview("loading"));

      // Hit API
      reviews
        .details(match.params.courseID)
        .then((res) => {
          // Set data
          dispatch(populateReview(res));

          // Set title page
          document.title = `Review - ${res?.my_course?.course?.name ?? ''} | DEVELOBE EDU`;

          // Update state
          setState({
            ...state,
            note: res?.review?.note ?? "",
            rating: res?.review?.rating ?? 5,
            is_exist: res?.review ? true : false,
            review_id: res?.review?.id ?? -1,
            course_id: res?.my_course?.course_id ?? "",
            course_name: res?.my_course?.course?.name ?? "",
          });
        })
        .catch((err) => {
          // Set error flag
          dispatch(statusReview("error"));

          // Navigate to review list
          navigate("/reviews");
        });
    } else {
      dispatch(populateReview(null));

      // Update state
      setState({
        ...state,
        note: "",
        rating: 5,
        is_exist: false,
        review_id: -1,
        course_id: "",
        course_name: "",
      });
    }
  }, [match.params.courseID, dispatch]);

  return (
    <div>
      { REVIEW.status === "loading" && (
        <div className="w-full h-full fixed bg-black bg-opacity-50 z-50 flex justify-center items-center">
          <ReactLoading type="bubbles" color="#38B6FF" height="100px" width="100px"/>
        </div>
      )}
      
      <section className="py-6 min-h-0 md:min-h-0 fixed bg-white w-full z-20 drop-shadow-sm">
        <div className="container mx-auto px-4">
          <Header onDashboard={true}/>
        </div>
      </section>

      <section className="flex">
        <div className="hidden sm:block">
          <Sidebar></Sidebar>
        </div>
        <main className="flex-1">
          <div className="px-4 sm:px-16 mt-24 sm:mt-32">
            <section className="flex flex-col mt-8 pl-0">
              <h1 className="text-2xl sm:text-4xl text-gray-900 font-medium">
                Form Review
              </h1>
            </section>

            <ReviewForm state={state} setState={setState} setKey={setKey}></ReviewForm>
          </div>
        </main>
      </section>
    </div>
  );
}
