import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "assets/images/logo.svg"
import propTypes from "prop-types";
import users from "constants/api/users";
import Cookies from 'js-cookie'
function Header({onDashboard}) {
  const location = useLocation();
  const navigate = useNavigate();
  const [toggleMenu, setToggleMenu] = React.useState(false);

  const linkCTA = location.pathname.indexOf("/login") > -1 ? `/register` : `/login`;
  const textCTA = location.pathname.indexOf("/login") > -1 ? "Daftar" : "Masuk";

  function logout() {
    users.logout().then(() => {
      localStorage.removeItem("DEVELOBE:token");
      Cookies.remove('DEVELOBE:user', {path: '/', domain: 'develobe.id'});
      navigate("/login");
    });
  }

  const getNavLinkClass = (path) => {
    return location?.pathname === path
      ? "active text-develobe-500 bg-develobe-50"
      : "text-black-500";
  };

  return (
    <header
      className={[
        "flex justify-between items-center",
        toggleMenu ? "sm:fixed w-full sm:-mx-4 sm:px-4" : "",
      ].join(" ")}>
      <div>
        <a href={`${process.env.REACT_APP_FRONTPAGE_URL}/`}>
          <Logo/>
        </a>
      </div>
      <div className="flex sm:hidden">
        <button
          onClick={() => setToggleMenu((prev) => !prev)}
          className={["toggle z-50", toggleMenu ? "active" : ""].join(" ")}
        ></button>
      </div>
      <ul
        className={[
          "transition-all duration-200 items-center fixed inset-0 bg-white h-screen sm:h-fit pt-24 sm:pt-0 sm:bg-transparent sm:relative sm:flex sm:opacity-100 sm:visible",
          "opacity-0 invisible",
        ].join(" ")}
      >
        <li className="my-2 sm:my-0">
          <a href={`${process.env.REACT_APP_FRONTPAGE_URL}/`}
            className="text-black hover:text-develobe-500 text-lg px-6 py-3 font-medium">
            Home
          </a>
        </li>
        <li className="my-2 sm:my-0">
          <a href={`${process.env.REACT_APP_FRONTPAGE_URL}/courses`}
            className="text-black hover:text-develobe-500 text-lg px-6 py-3 font-medium">
            Kelas
          </a>
        </li>
        <li className="my-2 sm:my-0">
          <a href={`${process.env.REACT_APP_FRONTPAGE_URL}/blogs`}
            className="text-black hover:text-develobe-500 text-lg px-6 py-3 font-medium">
            Blog
          </a>
        </li>
        <li className="my-2 sm:my-0">
          <a href="https://discord.gg/mCqP3WBXgB"
            target="_blank"
            className="text-black hover:text-develobe-500 text-lg px-6 py-3 font-medium">
            Bantuan
          </a>
        </li>
        { onDashboard ? (
          <></>
        ) : (
          <li>
            <Link
              to={linkCTA}
              className="rounded bg-develobe-500 hover:drop-shadow-lg transition-all duration-200 text-white text-lg px-6 py-3 font-medium ml-6">
                {textCTA}
            </Link>
          </li>
        )}
      </ul>
      <ul
        className={[
          "block sm:hidden transition-all duration-200 items-center fixed inset-0 bg-white h-screen sm:h-fit pt-16 px-5 sm:pt-0 sm:bg-transparent sm:relative sm:opacity-100 sm:visible",
          toggleMenu ? "opacity-100 visible z-50" : "opacity-0 invisible",
        ].join(" ")}
      >
        { onDashboard ? (
          <>
            <li className="my-2 sm:my-0">
              <Link
                className={[
                  "nav-link rounded-md relative flex items-center py-3 px-5 transition-all duration-200 hover:text-develobe-500 w-full text-left",
                  getNavLinkClass("/"),
                ].join(" ")}
                to="/">
                Kelas Saya
              </Link>
            </li>
            <li className="my-2 sm:my-0">
              <Link
                className={[
                  "nav-link rounded-md relative flex items-center py-3 px-5 transition-all duration-200 hover:text-develobe-500 w-full text-left",
                  getNavLinkClass("/transactions"),
                ].join(" ")}
                to="/transactions">
                Transaksi
              </Link>
            </li>
            <li className="my-2 sm:my-0">
              <Link
                className={[
                  "nav-link rounded-md relative flex items-center py-3 px-5 transition-all duration-200 hover:text-develobe-500 w-full text-left",
                  getNavLinkClass("/settings"),
                ].join(" ")}
                to="/settings">
                Pengaturan
              </Link>
            </li>
            <li className="my-2 sm:my-0">
              <Link
                className={[
                  "nav-link rounded-md relative text-black-500 flex items-center py-3 px-5 transition-all duration-200 hover:text-develobe-500 w-full text-left",
                ].join(" ")}
                onClick={logout}>
                Keluar
              </Link>
            </li>
          </>
        ) : (
          <>
            <li className="mb-4 mt-8 sm:my-0">
              <a href={`${process.env.REACT_APP_FRONTPAGE_URL}/`}
                className="text-black hover:text-develobe-500 text-lg px-3 py-3 font-medium">
                Home
              </a>
            </li>
            <li className="my-4 sm:my-0">
              <a href={`${process.env.REACT_APP_FRONTPAGE_URL}/courses`}
                className="text-black hover:text-develobe-500 text-lg px-3 py-3 font-medium">
                Kelas
              </a>
            </li>
            <li className="my-4 sm:my-0">
              <a href={`${process.env.REACT_APP_FRONTPAGE_URL}/blogs`}
                className="text-black hover:text-develobe-500 text-lg px-3 py-3 font-medium">
                Blog
              </a>
            </li>
            <li className="my-4 sm:my-0">
              <a href="https://discord.gg/mCqP3WBXgB"
                target="_blank"
                className="text-black hover:text-develobe-500 text-lg px-3 py-3 font-medium">
                Bantuan
              </a>
            </li>
            <li className="my-8">
              <Link
                to={linkCTA}
                className="rounded bg-develobe-500 hover:drop-shadow-lg transition-all duration-200 text-white text-lg px-6 py-3 font-medium ml-3 sm:ml-6">
                  {textCTA}
              </Link>
            </li>
          </>
        )}
      </ul>
    </header>
  );
}

Header.propTypes = {
  onDashboard: propTypes.bool,
};

export default Header;
