import React from "react";

import { Link } from "react-router-dom";

export default function Unauthenticated({
  fallbackUrl,
  fallbackText,
  external,
}) {
  return (
    <section className="h-screen flex flex-col items-center pt-20">
      <img
        src={`${process.env.PUBLIC_URL}/assets/images/undraw_security.svg`}
        className="max-h-80"
      />
      <h1 className="text-3xl text-gray-900 mt-12">Keamanan</h1>
      <p className="text-lg text-gray-600 mt-4 mtb-8 lg:w-3/12 xl:w-3/12 mx-auto text-center">
        Maaf Anda tidak memiliki akses untuk melihat halaman ini
      </p>
      {external ? (
        <a
          className="bg-develobe-500 hover:drop-shadow-xl rounded-md transition-all duration-200 focus:outline-none shadow-inner text-white px-6 py-3 mt-5"
          href={fallbackUrl}
        >
          {fallbackText || "Kembali ke halaman login"}
        </a>
      ) : (
        <Link
          className="bg-develobe-500 hover:drop-shadow-xl rounded-md transition-all duration-200 focus:outline-none shadow-inner text-white px-6 py-3 mt-5"
          to={fallbackUrl || "/login"}
        >
          {fallbackText || "Kembali ke halaman login"}
        </Link>
      )}
    </section>
  );
}
