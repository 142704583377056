import React from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCheck } from "@fortawesome/free-solid-svg-icons";

function SidebarClass({ data, defaultUri, toggleMenu }) {
  const params = useLocation();

  const getNavLinkClass = (path) => {
    return params?.pathname === path || defaultUri === path
      ? "text-develobe-500 bg-develobe-500 bg-opacity-10"
      : "text-gray-500";
  };

  const sidebarStyle = {
    width: 360,
    left: window.innerWidth < 640 && !toggleMenu ? "-360px" : 0,
  };

  const list = [];
  data.chapters.forEach((chapter, index) => {
    list.push(
      <li key={`${chapter.course_id}-${index}`}>
        <span className="nav-header relative block py-3 px-5 text-black font-bold text-left -ml-4">
          {chapter?.name ?? "Chapter name"}
        </span>
      </li>
    );
    if (chapter?.lessons?.length > 0)
      chapter.lessons.forEach((lesson, index2) => {
        list.push(
          <li key={`${chapter.course_id}-${lesson.id}-${index2}`}>
            <Link
              className={[
                "relative flex items-center py-3 px-5 transition-all duration-200 w-full hover:text-develobe-500 text-left truncate ... rounded-md",
                getNavLinkClass(
                  `/courses/${data.id}/${chapter.id}/${lesson.id}`
                ),
              ].join(" ")}
              to={`/courses/${data.id}/${chapter.id}/${lesson.id}`}
            >
              <div className="w-full">
                {lesson?.name ?? "Lesson name"}
              </div>
              { lesson?.is_completed && (
                <div className="min-w-fit">
                  <FontAwesomeIcon icon={faCheck} className="text-develobe-500"/>
                </div>
              )}
            </Link>
          </li>
        );
      });
  });

  return (
    <>
      <aside
        className="transition-all duration-300 z-10 absolute top-0 sm:relative max-h-screen h-screen sm:ml-10 sm:py-10"
        style={sidebarStyle}
      >
        {toggleMenu && (
          <div
            className="overlay"            
          ></div>
        )}
        <div
          className={[
            "h-full bg-white flex flex-col content-between z-10 drop-shadow-xl border border-gray-100 sm:rounded-xl py-8 sm:py-5 px-5 sm:opacity-100 sm:visible",
            toggleMenu ? "opacity-100 visible z-50" : "opacity-0 invisible",
          ].join(" ")}
          style={{ width: 360 }}
        >
          <ul className="main-menu overflow-y-auto">
            <li>
              <Link
                className="relative flex items-center py-3 w-full text-left text-black mb-0 hover:text-develobe-500 duration-200"
                to="/"
              >
                <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                Kembali ke Member Area
              </Link>
            </li>
            {list}
          </ul>
        </div>
      </aside>
    </>
  );
}

export default SidebarClass;
