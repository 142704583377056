import React from "react";
import { Link } from "react-router-dom";

export default function ListClassItem({ data }) {
  return (
    <div className="card rounded-md">
      <div className="item relative">
        <figure className="item-image max-h-48 overflow-hidden rounded-t-md">
          <img
            className="w-full object-cover duration-300 thumbnail"
            src={data?.thumbnail ?? ""}
            alt={data?.name ?? ""}
          />
        </figure>
        <div className="item-meta p-4">
          <div className="flex justify-between items-center">
            <h4 className="w-auto text-sm text-gray-600">
            { data?.total_video } Lessons
            </h4>
            <h4 className="w-auto text-sm text-gray-600 capitalize">
              {data?.level ?? ""}
            </h4>
          </div>

          <h3 className="text-lg text-black mt-2 line-clamp-2">
            {data?.name ?? ""}
          </h3>

          <div className="flex items-center mt-3">
            <figure className="overflow-hidden rounded-full w-8 h-8 border-2 border-white drop-shadow-xl">
              <img
                src={data?.mentor?.profile ?? ""}
                alt={data?.mentor?.name ?? ""}
              />
            </figure>
            <span className="ml-3 text-sm text-gray-600">{data?.mentor?.name}</span>
          </div>
        </div>
        <Link to={`/courses/${data.id}`} className="link-wrapped"></Link>
      </div>
    </div>
  );
}
