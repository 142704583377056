import React, { useEffect } from "react";
import Sidebar from "parts/Sidebar";
import Header from "parts/Header";
import ReactLoading from 'react-loading';
import { useLocation, useNavigate } from "react-router-dom";
import media from "constants/api/media";
import { useDispatch, useSelector } from "react-redux";
import { populateGift, statusGift } from "store/actions/media";
import useForm from "helpers/hooks/useForm";
import formatTimestamp from "helpers/formatTimestamp";
import checkExpired from "helpers/checkExpired";

export default function Gift() {
  const location = useLocation();
  const dispatch = useDispatch();
  const GIFT = useSelector((state) => state.media);
  const params = location.pathname.split('/');
  const navigate = useNavigate();
  let match = {};

  const [state, setKey, setState] = useForm({
    id: -1,
    slug: "",
    title: "",
    description: "",
    thumbnail: "",
    image: "",
    url: "",
    expireAt: "",
    createdAt: "",
    updatedAt: ""
  });

  if (params[2]){
    match = {
      params: {
        slug: params[2]
      }
    }
  }

  useEffect(() => {
    // Scroll to top
    window.scroll(0, 0);

    if (match.params.slug){
      // Show loader
      dispatch(statusGift("loading"));

      // Hit API
      media
        .giftDetails(match.params.slug)
        .then((res) => {
          // Set data
          dispatch(populateGift(res.data));

          // Set title page
          document.title = `${res?.data?.title ?? ''} | DEVELOBE EDU`;

          // Update state
          setState({
            ...state,
            id: res?.data?.id ?? -1,
            slug: res?.data?.slug ?? "",
            title: res?.data?.title ?? "",
            description: res?.data?.description ?? "",
            thumbnail: res?.data?.thumbnail ?? "",
            image: res?.data?.image ?? "",
            url: res?.data?.url ?? "",
            expireAt: res?.data?.expireAt ?? "",
            createdAt: res?.data?.createdAt ?? "",
            updatedAt: res?.data?.updatedAt ?? "",
          });
        })
        .catch((err) => {
          // Set error flag
          dispatch(statusGift("error"));

          // Navigate to gift list
          navigate("/gift");
        });
    }
  }, [match.params.slug, dispatch]);

  return (
    <div>
      { GIFT.status === "loading" && (
        <div className="w-full h-full fixed bg-black bg-opacity-50 z-50 flex justify-center items-center">
          <ReactLoading type="bubbles" color="#38B6FF" height="100px" width="100px"/>
        </div>
      )}
      
      <section className="py-6 min-h-0 md:min-h-0 fixed bg-white w-full z-20 drop-shadow-sm">
        <div className="container mx-auto px-4">
          <Header onDashboard={true}/>
        </div>
      </section>

      <section className="flex">
        <div className="hidden sm:block">
          <Sidebar></Sidebar>
        </div>
        <main className="flex-1">
          <div className="px-4 sm:px-16 mt-24 sm:mt-32">
            <section className="flex flex-col mt-8 pl-0 pb-20">
              <h1 className="text-2xl sm:text-4xl text-gray-900 font-medium">
                { state?.title ?? "" }
              </h1>

              <img
                className="w-full object-contain duration-300 mt-5"
                src={state?.image ?? ""}
                alt={state?.title ?? ""}
              />

              { state?.expireAt && (
                <span className="mt-5 font-light text-sm">
                  Berakhir pada
                  { state?.expireAt && (
                    <>: { formatTimestamp(state.expireAt) }</>
                  )}
                </span>
              )}

              <div className="mt-5" dangerouslySetInnerHTML={{ __html: state?.description}}>
              </div>

              { state?.expireAt && (
                checkExpired(state.expireAt) ? (
                  <button
                    className={[
                      "w-fit text-white px-5 py-2 rounded-md mx-auto mt-5",
                      "bg-gray-500 cursor-not-allowed"
                    ].join(" ")}
                    disabled={true}>
                    Download
                  </button>
                ) : (
                  <a 
                    className={[
                      "w-fit text-white px-5 py-2 rounded-md mx-auto mt-5",
                      "bg-develobe-500 cursor-pointer"
                    ].join(" ")}
                    href={state?.url}
                    download={state?.title}
                    target="_blank">
                    Download
                  </a>
                )
              )}
            </section>
          </div>
        </main>
      </section>
    </div>
  );
}
