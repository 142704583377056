import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faDiscord } from "@fortawesome/free-brands-svg-icons";

export default function Footer() {
  return (
    <footer className="container px-4 mx-auto pb-0 md:pb-20">
      <div className="flex flex-col md:flex-row md:flex-wrap md:justify-between">
        <div className="w-full md:w-1/3 mb-8 md:mb-0">
          <img src="/images/logo.svg" alt="" />
          <h6 className="text-black font-light mt-5 text-sm leading-normal">Kami menyediakan beragam kelas gratis hingga premium untuk membantu Anda memahami materi yang Anda inginkan tanpa batasan waktu. #BisaKarenaTerbiasa</h6>
          <h6 className="text-black font-light mt-2 text-sm leading-normal">&copy; 2022 DEVELOBE.ID</h6>
          <a href="https://instagram.com/develobe.education" target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faInstagram} className="text-black text-3xl mt-2 mx-1 hover:text-develobe-500 cursor-pointer duration-200" />
          </a>
          <a href="https://discord.gg/mCqP3WBXgB" target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faDiscord} className="text-black text-3xl mt-2 mx-1 hover:text-develobe-500 cursor-pointer duration-200" />
          </a>
        </div>

        <div className="w-full md:w-1/6 mb-8 md:mb-0">
          <h6 className="text-black font-bold">Website</h6>
          <ul className="mt-4">
            <li className="mt-2">
              <a href={`${process.env.REACT_APP_FRONTPAGE_URL}/courses`} className="text-black hover:text-develobe-500 hover:underline">
                  Kelas
              </a>
            </li>
            <li className="mt-2">
              <a href={`${process.env.REACT_APP_FRONTPAGE_URL}/blogs`} className="text-black hover:text-develobe-500 hover:underline">
                Blog
              </a>
            </li>
            <li className="mt-2">
              <a href={`${process.env.REACT_APP_FRONTPAGE_URL}/help`} className="text-black hover:text-develobe-500 hover:underline">
                Bantuan
              </a>
            </li>
          </ul>
        </div>

        <div className="w-full md:w-1/6 mb-8 md:mb-0">
          <h6 className="text-black font-bold">DEVELOBE.ID</h6>
          <ul className="mt-4">
            <li className="mt-2">
              <a href="https://develobe.id" className="text-black hover:text-develobe-500 hover:underline" target="_blank" rel="noreferrer">
                Tentang DEVELOBE.ID
              </a>
            </li>
            <li className="mt-2">
              <a href="https://instagram.com/develobe.id" className="text-black hover:text-develobe-500 hover:underline" target="_blank" rel="noreferrer">
                Kerja Sama
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}
