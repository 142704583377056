import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "parts/Sidebar";
import media from "constants/api/media";
import Loading from "parts/Loading";

import {
  statusGift,
  populateGift,
} from "store/actions/media";

import Header from "parts/Header";
import ListGiftItem from "parts/ListGiftItem";

function EmptyState() {
  return (
    <section className="flex h-screen items-center relative z-10 bg-white">
      <div className="w-full sm:w-8/12 text-center py-12 mx-auto">
        <h1 className="text-3xl text-gray-900 mt-12">Belum ada data</h1>
      </div>
    </section>
  );
}

export default function MyGift() {
  const dispatch = useDispatch();
  const GIFT = useSelector((state) => state.media);

  useEffect(() => {
    window.scroll(0, 0);
    document.title = "Gift | DEVELOBE EDU";

    dispatch(statusGift("loading"));
    media
      .gift()
      .then((res) => {
        dispatch(populateGift(res.data));
      })
      .catch((err) => {
        dispatch(statusGift("error"));
      });
  }, [dispatch]);

  return (
    <div>
      <section className="py-6 min-h-0 md:min-h-0 fixed bg-white w-full z-20 drop-shadow-sm">
        <div className="container mx-auto px-4">
          <Header onDashboard={true}/>
        </div>
      </section>
      <section className="flex">
        <div className="hidden sm:block">
          <Sidebar></Sidebar>
        </div>

        <main className="flex-1 w-full sm:w-fit px-4 sm:px-0">
          <div className="px-0 sm:px-16 mb-10 sm:mb-10">
            {GIFT.status === "loading" && <Loading></Loading>}
            {GIFT.status === "ok" &&
              (GIFT.total > 0 ? (
                <>
                  <section className="flex flex-col pl-0 mt-24 sm:mt-32">
                    <h1 className="text-2xl sm:text-4xl text-gray-900 font-medium">
                      Gift
                    </h1>
                  </section>
                  <section className="flex flex-col mt-2 sm:mt-5">
                    <div className="grid grid-cols-1 gap-y-3 sm:gap-3 justify-start items-center">
                      {Object.values(GIFT.data)?.map?.((item, index) => {
                        return (
                          <div key={index}>
                            <ListGiftItem data={item}/>
                          </div>
                        );
                      })}
                    </div>
                  </section>
                </>
              ) : (
                <EmptyState></EmptyState>
              ))}
          </div>
        </main>
      </section>
    </div>
  );
}
