import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Sidebar from "parts/Sidebar";
import SettingForm from "parts/SettingForm";
import Header from "parts/Header";
import ReactLoading from 'react-loading';

export default function Settings() {
  useEffect(() => {
    window.scroll(0, 0);
    document.title = "Pengaturan | DEVELOBE EDU";
  }, []);

  const DETAILS = useSelector((state) => state.users);
  const [loading, setLoading] = useState(() => false);

  function showLoading(isVisible) {
    setLoading(isVisible);
  }

  return (
    <div>
      { loading && (
        <div className="w-full h-full fixed bg-black bg-opacity-50 z-50 flex justify-center items-center">
          <ReactLoading type="bubbles" color="#38B6FF" height="100px" width="100px"/>
        </div>
      )}
      
      <section className="py-6 min-h-0 md:min-h-0 fixed bg-white w-full z-20 drop-shadow-sm">
        <div className="container mx-auto px-4">
          <Header onDashboard={true}/>
        </div>
      </section>

      <section className="flex">
        <div className="hidden sm:block">
          <Sidebar></Sidebar>
        </div>
        <main className="flex-1">
          <div className="px-4 sm:px-16 mt-24 sm:mt-32">
            <section className="flex flex-col mt-8 pl-0">
              <h1 className="text-2xl sm:text-4xl text-gray-900 font-medium">
                Pengaturan Data
              </h1>
            </section>

            <SettingForm details={DETAILS} showLoading={showLoading}></SettingForm>
          </div>
        </main>
      </section>
    </div>
  );
}
