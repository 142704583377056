import React, { useState, useRef } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "components/Form/Select";
import Input from "components/Form/Input";
import { useDispatch } from "react-redux";
import { statusReview } from "store/actions/reviews";
import reviews from "constants/api/reviews";
import { useNavigate } from "react-router-dom";

function ReviewForm({ state, setKey, setState }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  async function submit(e) {
    e.preventDefault();

    // Check is update or create review
    if (state?.is_exist){
      // Update Review
      // Set payload
      const payload = {
        note: state?.note,
        rating: state?.rating,
      };

      // Show loader
      dispatch(statusReview("loading"));
      
      // Hit API
      reviews
        .update(state?.review_id, payload)
        .then((res) => {
          // Hide loader
          dispatch(statusReview("ok"));
          
          // Show success notification
          toast.success("Review berhasil diupdate");
          
          // Update state
          setState({
            ...state,
            note: payload.note,
            rating: payload.rating,
          });

          // Navigate to review list
          navigate("/reviews");
        })
        .catch((error) => {
          // Hide loader
          dispatch(statusReview("error"));
          
          // Show success notification
          toast.error(error?.response?.data?.message ?? "Update review gagal");
        });
    } else {
      // Create Review
      // Set payload
      const payload = {
        note: state?.note,
        rating: state?.rating,
        course_id: state?.course_id,
      };

      // Show loader
      dispatch(statusReview("loading"));
      
      // Hit API
      reviews
        .create(payload)
        .then((res) => {
          // Hide loader
          dispatch(statusReview("ok"));
          
          // Show success notification
          toast.success("Review berhasil dibuat");
          
          // Update state
          setState({
            ...state,
            note: payload.note,
            rating: payload.rating,
            course_id: payload.course_id,
          });

          // Navigate to review list
          navigate("/reviews");
        })
        .catch((error) => {
          // Hide loader
          dispatch(statusReview("error"));
          
          // Show success notification
          toast.error(error?.response?.data?.message ?? "Penambahan review gagal");
        });
    }
  }

  return (
    <>
      <section className="flex flex-col">
        <h3 className="my-4 text-md text-black">Course : {state?.course_name}</h3>
        <div className="flex items-center pb-24">
          <div className="w-full sm:w-6/12">
            <form onSubmit={submit}>
              <Input
                value={state.note}
                name="note"
                onChange={setKey}
                placeholder="Review Anda"
                labelName="Review Anda"
                required={true}
              />

              <Select
                labelName="Rating"
                name="rating"
                value={state.rating}
                fallbackText="Rating"
                onClick={setKey}
              >
                <option value={5}>5.0 - Sangat Bagus</option>
                <option value={4}>4.0 - Bagus</option>
                <option value={3}>3.0 - Cukup</option>
                <option value={2}>2.0 - Biasa</option>
                <option value={1}>1.0 - Kurang Memuaskan</option>
              </Select>

              <button
                type="submit"
                className="bg-develobe-500 hover:drop-shadow-xl rounded-md transition-all duration-200 focus:outline-none shadow-inner text-white px-6 py-3 mt-4"
              >
                { state?.is_exist ? "Update" : "Simpan" }
              </button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}
export default ReviewForm;
