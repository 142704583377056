import checkExpired from "helpers/checkExpired";
import formatTimestamp from "helpers/formatTimestamp";
import React from "react";
import { Link } from "react-router-dom";

export default function ListGiftItem({ data }) {
  return (
    <div className="card rounded-md p-4">
      <div className="flex flex-row w-full">
        <div className="w-20">
          <figure className="max-h-20 max-w-20 w-20 h-20 overflow-hidden rounded-md">
            <img
              className="w-full object-cover duration-300 thumbnail"
              src={data?.thumbnail ?? ""}
              alt={data?.title ?? ""}
            />
          </figure>
        </div>
        <div className="ml-4 w-full">
          <h3 className="text-lg text-black line-clamp-2">
            {data?.title ?? ""}
          </h3>

          Berakhir pada
          { data?.expire_at && (
            <>: { formatTimestamp(data.expire_at) }</>
          )}

          { data?.expire_at ? (
            checkExpired(data.expire_at) ? (
              <div>Tidak Aktif</div>
            ) : (
              <div>Aktif</div>
            )
          ) : (
            <></> 
          )}
        </div>
        <Link to={`/gift/${data.slug}`} className="link-wrapped"></Link>
      </div>
    </div>
  );
}
