import { 
  POPULATE_REVIEW, 
  STATUS_REVIEW 
} from "constants/types/reviews";

const initialState = {
  data: {},
  status: "idle",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case STATUS_REVIEW:
      return {
        status: action.payload,
      };

    case POPULATE_REVIEW:
      return {
        data: action.payload,
        status: "ok"
      };

    default:
      return state;
  }
}
