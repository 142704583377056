import React, { useEffect } from "react";
import Youtube from "react-youtube";
import { useDispatch, useSelector } from "react-redux";

import {
  statusCourses,
  watchCourse,
  messageCourse,
} from "store/actions/courses";

import SidebarClass from "parts/SidebarClass";
import courses from "constants/api/courses";

import Loading from "parts/Loading";
import Centered from "parts/Centered";
import { useLocation, useNavigate } from "react-router-dom";
import ReactPlayer from 'react-player';
import { createReactEditorJS } from 'react-editor-js'
import { EDITOR_JS_TOOLS } from '../constants/editor-plugins'

export default function DetailsClass() {
  const dispatch = useDispatch();
  const COURSES = useSelector((state) => state.courses);
  const location = useLocation();
  const params = location.pathname.split('/');
  let match = {};
  const [toggleMenu, setToggleMenu] = React.useState(false);
  const navigate = useNavigate();

  const ReactEditorJS = createReactEditorJS()
  
  // /courses/:class == array length is 3
  // /courses/:class/:chapter/:uid == array length is 5
  if (params.length == 5){ // Chapter ID
    match = {
      params: {
        class: params[2],
        chapter: params[3],
        uid: params[4]
      }
    }
  } else { // Course ID
    match = {
      params: {
        class: params[2]
      }
    }
  }

  useEffect(() => {
    window.scroll(0, 0);

    dispatch(statusCourses("loading"));
    courses
      .progress(match.params.class)
      .then((res) => {
        if (res.chapters.length === 0)
          throw new Error("Class might be not ready yet");
        else {
          dispatch(watchCourse(res));
          
          // Set title page
          document.title = `Kelas - ${res?.name ?? ''} | DEVELOBE EDU`;
        }
      })
      .catch((err) => {
        dispatch(messageCourse(err?.response?.data?.message ?? "error"));
        
        // Redirect to index page
        navigate("/");
      });
  }, [match.params.class, dispatch]);

  if (COURSES.status === "loading") return <Loading></Loading>;
  if (COURSES.status === "error")
    return <Centered>{COURSES?.message ?? "Error here"}</Centered>;

  const TYPE_YOUTUBE = 0;
  const TYPE_CDN = 1;

  let currentChapter, currentLesson, nextLesson;
  let videoType = TYPE_YOUTUBE;
  var isLastLesson = false;
  let isJSONFormat = false;

  if (
    COURSES.status === "ok" &&
    COURSES?.data?.[match.params.class]?.chapters
  ) {
    // Check current chapter
    currentChapter =
      COURSES?.data?.[match.params.class]?.chapters?.find(
        (chapter) => chapter.id === match.params.chapter
      ) ?? COURSES.data[match.params.class]?.chapters[0];

    // Search current lesson by lesson ID
    currentLesson =
      currentChapter?.lessons?.find(
        (lesson) => lesson.id === match.params.uid
      ) ?? currentChapter?.lessons?.[0];

    // Check video type
    if (currentLesson){
      if (currentLesson.video?.includes('https')){
        videoType = TYPE_CDN;
      }

      if (currentLesson.description){
        try {
          currentLesson.description = JSON.parse(currentLesson.description);
          isJSONFormat = true;
        } catch (err){
          isJSONFormat = false;
        }
      }
    }

    // Search through chapter and lessons object
    for(const item of COURSES?.data?.[match.params.class]?.chapters){
      nextLesson = item.lessons?.find((lesson) => (lesson.is_completed == false && lesson.id != currentLesson.id))
      if (nextLesson){
        break;
      }
    }

    // Check is last lesson from course
    isLastLesson = !nextLesson;
  }

  async function nextVideo() {
    if (!currentLesson.is_completed){
      // Set course progress
      const bodyData = {
        course_id: match.params.class,
        lessons_id: currentLesson.id,
        status: 1,
      }

      const progressData = await courses.set_progress(bodyData);
      if (progressData){
        currentLesson.is_completed = 1;
      }
    }

    // Check is last lesson
    if (isLastLesson){
      // Redirect to index page
      navigate("/");
    } else {
      // Prepare variable
      var nextLesson = null;
      
      // Search through chapter and lessons object
      for(const item of COURSES?.data?.[match.params.class]?.chapters){
        nextLesson = item.lessons?.find((lesson) => (lesson.is_completed == false && lesson.id != currentLesson.id))
        if (nextLesson){
          break;
        }
      }

      // Check next video
      if (nextLesson){
        // Redirect to next video
        navigate(`/courses/${match.params.class}/${nextLesson.chapter_id}/${nextLesson.id}`)
      }
    }
  }

  return (
    <div className="flex sm:flex-row flex-col">
      {COURSES.data?.[match.params.class]?.chapters?.length > 0 && (
        <>
          <div className="flex sm:hidden w-screen justify-end p-4">
            <button
              onClick={() => setToggleMenu((prev) => !prev)}
              className={["toggle z-50", toggleMenu ? "active" : ""].join(" ")}/>
          </div>
          <div className="sm:block">
            <SidebarClass
              data={COURSES.data[match.params.class]}
              defaultUri={`/courses/${match.params.class}/${currentChapter.id}/${currentLesson.id}`}
              toggleMenu={toggleMenu}/>
          </div>
          <main className="flex-1">
            <div className="px-5 sm:px-16">
              <section className="flex flex-col mt-4 sm:mt-12">
                <div className="flex justify-start items-center">
                  <div className="w-full h-screen video-youtube">
                    {(currentLesson?.video && videoType == TYPE_YOUTUBE) && (
                      <Youtube
                        videoId={currentLesson?.video}
                        id={currentLesson?.video}
                        opts={{
                          playerVars: {
                            autoplay: 1,
                            controls: 1,
                            showinfo: 0,
                            rel: 0,
                          },
                        }}
                        onReady={(event) => {
                          event.target.playVideo();
                        }}
                        onEnd={nextVideo}
                      ></Youtube>
                    )}

                    {(currentLesson?.video && videoType == TYPE_CDN) && (
                      <ReactPlayer 
                        url={currentLesson?.video}
                        controls={true} 
                        width='100%'
                        className='bg-black'
                        playing={true}
                        config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                        onEnded={nextVideo}/>
                    )}

                    <section className="flex flex-row items-center mt-0 sm:mt-8 pl-0">
                      <div className="w-full">
                        <h1 className="text-xl sm:text-4xl text-gray-900 font-medium">
                          {currentLesson?.name ?? "Lesson Name"}
                        </h1>
                      </div>

                      <div className="min-w-fit bg-develobe-500 py-2 px-4 rounded-md text-white cursor-pointer hover:drop-shadow-xl duration-200 text-base"
                        onClick={nextVideo}>
                        { isLastLesson ? "Selesai" : "Next Video" }
                      </div>
                    </section>

                    { isJSONFormat &&  (
                      <div className="prose">
                        <ReactEditorJS defaultValue={currentLesson?.description} tools={EDITOR_JS_TOOLS} readOnly={true} />
                      </div>
                    )}

                    
                    { !isJSONFormat && (
                      <div className="mt-10 text-gray-700 font-normal text-md leading-8 text-justify" dangerouslySetInnerHTML={{ __html: currentLesson?.description}}>
                      </div>
                    )}

                    {/* Spacer */}
                    <div className="h-32"></div>
                  </div>
                </div>
              </section>
            </div>
          </main>
        </>
      )}
    </div>
  );
}
