import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "components/Form/Select";
import Input from "components/Form/Input";
import useForm from "helpers/hooks/useForm";
import fieldErrors from "helpers/fieldErrors";
import users from "constants/api/users";
import media from "constants/api/media";
import { populateProfile } from "store/actions/users";
import image2base64 from "utils/image2base64";
import { ReactComponent as DefaultUser } from "assets/images/default-avatar.svg";
import Cookies from 'js-cookie';

function SettingForm({ details, showLoading }) {
  const dispatch = useDispatch();
  const addPicture = useRef(null);

  const [state, setKey, setState] = useForm({
    name: details?.name ?? "",
    email: details?.email ?? "",
    profession: details?.profession ?? "",
    avatar: details?.avatar ?? "",
    password: details?.password ?? "",
    otherProfession: details?.profession ?? "",
  });

  const [errors, setErrors] = useState(null);

  function previewImage(e) {
    // Set max upload size
    const MAX_FILE_SIZE = 2048 // 2MB

    // Check image
    if (e.target.files.length > 0){
      // Get image and image size
      let selectedFile = e.target.files[0];
      const fileSizeKiloBytes = selectedFile.size / 1024;

      // Check image size
      if(fileSizeKiloBytes > MAX_FILE_SIZE){
        // Show error max size
        toast.error("Ukuran maksimal foto 2MB");
      } else {
        // Save image
        e.persist();
        image2base64(selectedFile).then((image) => {
          setKey({
            target: {
              name: e.target.name,
              value: image,
            },
          });
        });
      }
    }
  }

  async function submit(e) {
    e.preventDefault();

    const payload = {
      name: state.name,
      email: state.email,
      password: state.password,
      profession: state.profession,
    };
    if (payload.profession === "others")
      payload.profession = state.otherProfession;

    if (state.avatar.indexOf("base64") > -1) {
      showLoading(true);
      const avatar = await media.upload(state.avatar)
        .catch((error) => showLoading(false));
      payload.avatar = avatar.data.image;
    }

    showLoading(true);
    users
      .update(payload)
      .then((res) => {
        showLoading(false);
        toast.success("Profile berhasil diperbarui");
        setState({
          ...state,
          password: "",
        });
        setErrors(null);
        dispatch(
          populateProfile({
            ...details,
            ...res.data,
          })
        );

        // Set User Cookie
        const userCookie = {
          name: res.data.name,
          thumbnail: res.data.avatar,
        };

        const expires = new Date(
          new Date().getTime() + 7 * 24 * 60 * 60 * 1000
        );
        
        Cookies.remove('DEVELOBE:user', {path: '/', domain: 'develobe.id'});
        Cookies.set("DEVELOBE:user", JSON.stringify(userCookie), { path: '/', expires: expires, domain: 'develobe.id' });
      })
      .catch((error) => {
        showLoading(false);
        setErrors(error?.response?.data?.message ?? "errors")
      });
  }

  const ERRORS = fieldErrors(errors);

  return (
    <>
      <section className="flex flex-col mt-8">
        <div className="flex justify-start items-center">
          <div className="w-auto text-center px-5">
            <div className="rounded-full overflow-hidden w-24 h-24">
              {state.avatar ? (
                <img
                  className="object-cover w-full h-full"
                  src={state.avatar}
                  alt="Preview"
                />
              ) : (
                <DefaultUser
                  className="fill-indigo-500"
                  style={{ width: 90, height: 90 }}
                ></DefaultUser>
              )}
            </div>
          </div>
          <div className="w-full flex flex-col">
            <span className="text-gray-600">Perbarui avatar (Max 2MB)</span>
            <div>
              <input
                type="file"
                name="avatar"
                ref={addPicture}
                className="hidden"
                onChange={previewImage}
                accept="image/*"
              />
              <button
                onClick={() => addPicture.current.click()}
                className="bg-develobe-500 hover:drop-shadow-xl rounded-md transition-all duration-200 focus:outline-none shadow-inner text-white px-6 py-3 mt-3"
              >
                Cari File
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className="flex flex-col mt-8">
        <div className="flex items-center pb-24">
          <div className="w-full sm:w-6/12">
            <form onSubmit={submit}>
              <Input
                value={state.name}
                error={ERRORS?.name?.message}
                name="name"
                onChange={setKey}
                placeholder="Nama Anda"
                labelName="Nama Lengkap"
                required={true}
              />

              <Input
                value={state.email}
                error={ERRORS?.email?.message}
                name="email"
                type="email"
                onChange={setKey}
                placeholder="Email"
                labelName="Email"
                disabled={true}
              />

              <Input
                value={state.password}
                error={ERRORS?.password?.message}
                name="password"
                type="password"
                onChange={setKey}
                placeholder="Kata Sandi"
                labelName="Kata Sandi"
                required={true}
              />

              <Select
                labelName="Fokus Utama"
                name="profession"
                value={state.profession}
                fallbackText="Pilih fokus utama"
                onClick={setKey}
              >
                <option value="">Pilih fokus utama</option>
                <option value="Android Developer">Android Developer</option>
                <option value="Frontend Developer">Frontend Developer</option>
                <option value="Backend Developer">Backend Developer</option>
                <option value="UI Designer">UI Designer</option>
                <option value="others">Lainnya</option>
              </Select>

              {state.profession === "others" && (
                <Input
                  value={state.otherProfession}
                  error={ERRORS?.otherProfession?.message}
                  name="otherProfession"
                  type="text"
                  onChange={setKey}
                  placeholder="Your occupation"
                  labelName="Other Occupation"
                />
              )}

              <button
                type="submit"
                className="bg-develobe-500 hover:drop-shadow-xl rounded-md transition-all duration-200 focus:outline-none shadow-inner text-white px-6 py-3 mt-4"
              >
                Simpan
              </button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}
export default SettingForm;
