import { 
  POPULATE_GIFT, 
  STATUS_GIFT 
} from "constants/types/media";

const initialState = {
  data: {},
  status: "idle",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case STATUS_GIFT:
      return {
        status: action.payload,
      };

    case POPULATE_GIFT:
      return {
        data: action.payload,
        total: action.payload?.length ?? 0,
        status: "ok"
      };

    default:
      return state;
  }
}
