import axios from "axios";
import errorHandler from "./errorHandler";

axios.defaults.baseURL = `${process.env.REACT_APP_API_HOST}`;

axios.interceptors.request.use(function (config) {
  if (!config.headers.Authorization) {
    config.headers.Authorization = axios.defaults.headers.common['Authorization'];
  }
  
  return config;
}, function (error) {
  return Promise.reject(error);
});

axios.interceptors.response.use((response) => response.data, errorHandler);

export { default as setAuthorizationHeader } from "./setAuthorizationHeader";
export default axios;
