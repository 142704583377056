import React, { useEffect, useState } from "react";
import Header from "parts/Header";
import Footer from "parts/Footer";
import ForgotPasswordForm from "parts/ForgotPasswordForm";
import ReactLoading from 'react-loading';

export default function ForgotPassword() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const [loading, setLoading] = useState(() => false);

  function showLoading(isVisible) {
    setLoading(isVisible);
  }

  return (
    <main>
      { loading && (
        <div className="w-full h-full fixed bg-black bg-opacity-50 z-50 flex justify-center items-center">
          <ReactLoading type="bubbles" color="#38B6FF" height="100px" width="100px"/>
        </div>
      )}
      <section className="pt-10 min-h-0 md:min-h-0">
        <div className="container mx-auto px-4">
          <Header></Header>
        </div>
      </section>
      <section className="container mx-auto pt-10 px-4">
        <ForgotPasswordForm showLoading={showLoading}></ForgotPasswordForm>
      </section>
      <hr className="md:mt-12"/>
      <section className="pt-8 md:py-12">
        <Footer></Footer>
      </section>
    </main>
  );
}
