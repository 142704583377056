export default (date) => {
  const d = new Date(`${date} UTC`);
  const dtf = new Intl.DateTimeFormat("en", {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false,
    timeZoneName: "short"
  });

  const [
    { value: Fmonth },
    ,
    { value: Fdate },
    ,
    { value: Fyear },
    ,
    { value: Fhour },
    ,
    { value: Fminute },
    ,
    { value: Fsecond },
    ,
    { value: Ftimezone },
  ] = dtf.formatToParts(d);

  const parts = dtf.formatToParts(d);

  return `${Fdate} ${Fmonth} ${Fyear} ${Fhour}:${Fminute}:${Fsecond} ${Ftimezone}`;
};
