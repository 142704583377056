import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

export default function ListReviewItem({ data }) {
  return (
    <div className="card rounded-md p-4">
      <div className="flex flex-row w-full">
        <div className="w-20">
          <figure className="max-h-20 max-w-20 w-20 h-20 overflow-hidden rounded-md">
            <img
              className="w-full object-cover duration-300 thumbnail"
              src={data?.course?.thumbnail ?? ""}
              alt={data?.course?.name ?? ""}
            />
          </figure>
        </div>
        <div className="ml-4 w-full">
          <h3 className="text-lg text-black line-clamp-2">
            {data?.course?.name ?? ""}
          </h3>

          { data?.review ? (
            <>
              <div className="flex flex-row justify-start mt-1">
                {[...Array(data?.review?.rating ?? 0)].map(() =>
                  <FontAwesomeIcon icon={faStar} className="text-yellow-500"/>
                )}

                <h4 className="text-sm text-black capitalize ml-2 font-bold">
                  {data?.review?.rating?.toFixed(1) ?? "0"}
                </h4>
              </div>
              
              <h4 className="text-sm text-black capitalize mt-2">
                { data?.review?.note }
              </h4>
            </>
          ) : (
            <>Belum direview</>
          )}
        </div>
        <Link to={`/reviews/${data.course_id}`} className="link-wrapped"></Link>
      </div>
    </div>
  );
}
