import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "parts/Sidebar";
import formatThousand from "helpers/formatThousand";
import formatDate from "helpers/formatDate";
import orders from "constants/api/orders";
import Loading from "parts/Loading";
import Congratulation from "parts/Congratulation";
import EmptyState from "parts/EmptyState";
import { statusOrders, fetchOrders, messageOrder } from "store/actions/orders";
import Header from "parts/Header";

export default function Transactions() {
  const dispatch = useDispatch();
  const ORDERS = useSelector((state) => state.orders);

  const location = useLocation();

  const params =
    location?.search.length > 0 &&
    location?.search
      ?.substring(1, location.length)
      ?.split?.("&")
      ?.reduce?.((acc, item) => {
        const [key, value] = item.split("=");
        acc[key] = value;
        return acc;
      }, {});

  useEffect(() => {
    window.scroll(0, 0);
    document.title = "Transaksi | DEVELOBE EDU";

    dispatch(statusOrders("loading"));
    orders
      .all()
      .then((res) => {
        dispatch(fetchOrders(res.data));
      })
      .catch((err) => {
        dispatch(messageOrder(err?.response?.data?.message ?? "error"));
      });
  }, [dispatch]);

  var orderID = "";
  if (params.order_id){
    orderID = params.order_id.substr(0, params.order_id.indexOf('_'));
  }

  return (
    <div>
      <section className="py-6 min-h-0 md:min-h-0 fixed bg-white w-full z-20 drop-shadow-sm">
        <div className="container mx-auto px-4">
          <Header onDashboard={true}/>
        </div>
      </section>
      <section className="flex relative">
        <div className="hidden sm:block">
          <Sidebar></Sidebar>
        </div>
        <main className="flex-1">
          <div className="px-4 sm:px-16">
            {ORDERS.status === "loading" && <Loading />}
            {ORDERS.status === "error" && ORDERS.message}
            {ORDERS.status === "ok" &&
              (params.order_id ? (
                ORDERS.data[orderID] ? <Congratulation data={ORDERS.data[orderID]} />
                : <div className="h-screen flex justify-center flex-col items-center text-center">Mohon refresh halaman ini setelah pembayaran sukses.<br/>Jika kelas masih belum tampil, mohon hubungi Admin melalui Discord <a href="https://discord.gg/TndHNMZcXJ" target="_blank" className="text-develobe-500">https://discord.gg/TndHNMZcXJ</a></div>
              ) : ORDERS.total > 0 ? (
                <>
                  <section className="flex flex-col pl-0 mt-24 sm:mt-32">
                    <h1 className="text-2xl sm:text-4xl text-gray-900 font-medium">
                      Daftar Transaksi
                    </h1>
                  </section>
                  <section className="flex flex-wrap mb-10 sm:mb-0">
                    {Object.values(ORDERS.data)?.map?.((item) => {
                      return (
                        <div
                          key={item.id}
                          className="flex flex-wrap flex-col sm:flex-row justify-start sm:items-center my-2 bg-white rounded-md drop-shadow-md p-4"
                        >
                          <div className="w-full sm:w-2/12">
                            <img
                              src={item?.metadata?.course_thumbnail ?? ""}
                              alt={item?.metadata?.course_name ?? "Class name"}
                            />
                          </div>
                          <div className="w-auto sm:w-3/12 mt-2 sm:mt-0 px-0 sm:px-4">
                            <h6 className="text-gray-900 text-lg font-bold">
                              {item?.metadata?.course_name ?? "Class name"}
                            </h6>
                            <p className="text-gray-700 capitalize">
                              {item?.metadata?.course_level ?? "Level"}
                            </p>
                          </div>
                          <div className="w-full sm:w-2/12 px-0 sm:px-4">
                            <h6 className="text-gray-900 text-lg">
                              Rp{formatThousand(item?.metadata?.course_price ?? 0)}
                            </h6>
                          </div>
                          <div className="w-auto sm:w-2/12 px-0 sm:px-4">
                            <h6 className="text-gray-900 text-sm">
                              {item?.created_at
                                ? formatDate(item?.created_at)
                                : "-"}
                            </h6>
                          </div>
                          <div className="sm:w-3/12 mt-2 sm:mt-0 sm:px-4 flex sm:justify-center">
                            {item?.status === "success" && (
                              <Link
                                className="bg-develobe-500 hover:drop-shadow-md transition-all duration-200 focus:outline-none px-6 py-3 mt-0 sm:mt-4 whitespace-no-wrap ml-0 sm:ml-0 rounded-md text-white"
                                to={`/courses/${item?.course_id}`}
                              >
                                Lihat Kelas
                              </Link>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </section>
                </>
              ) : (
                <EmptyState />
              ))}
          </div>
        </main>
      </section>
    </div>
  );
}
