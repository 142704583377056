import React from "react";

import { Link } from "react-router-dom";

export default function ServerError() {
  return (
    <section className="h-screen flex flex-col items-center">
      <img
        src={`${process.env.PUBLIC_URL}/assets/images/undraw_page_not_found.svg`}
        alt="Server error"
      />
      <h1 className="text-3xl text-gray-900 mt-12">Oops! Terjadi Kendala</h1>
      <p className="text-lg text-gray-600 mt-4 mtb-8 lg:w-3/12 xl:w-3/12 mx-auto text-center">
        Mohon coba beberapa saat lagi
      </p>
      <Link
        className="bg-develobe-500 hover:drop-shadow-xl transition-all duration-200 focus:outline-none shadow-inner text-white px-6 py-3 mt-5 rounded-md"
        to="/">
        Kembali ke Home
      </Link>
    </section>
  );
}
