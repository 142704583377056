import React, { useEffect } from "react";
import { createBrowserHistory } from "history";
import { Routes, Route } from "react-router-dom";
import { useDispatch } from "react-redux";

import "index.css";

import MemberRoute from "components/Routes/MemberRoute";
import GuestRoute from "components/Routes/GuestRoute";

import NotFound from "pages/404";
import Unauthenticated from "pages/401";

import Login from "pages/Login";
import Register from "pages/Register";

import MyClass from "pages/MyClass";
import Joined from "pages/Joined";
import DetailsClass from "pages/DetailsClass";
import Settings from "pages/Settings";
import Transactions from "pages/Transactions";
import { setAuthorizationHeader } from "configs/axios";
import users from "constants/api/users";
import { populateProfile } from "store/actions/users";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ForgotPassword from "pages/ForgotPassword";
import MyReview from "pages/MyReview";
import Review from "pages/Review";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import ReactGA from "react-ga4";
import MyGift from "pages/MyGift";
import Gift from "pages/Gift";

function App() {
  const dispatch = useDispatch();
  ReactGA.initialize("G-9CD9JR0R3S"); // Set google analytic
  const history = createBrowserHistory({ basename: process.env.PUBLIC_URL });

  useEffect(() => {
    let session = null;
    if (localStorage.getItem("DEVELOBE:token")) {
      session = JSON.parse(localStorage.getItem("DEVELOBE:token"));
      setAuthorizationHeader(session.token);

      users.details().then((details) => {
        dispatch(populateProfile(details.data));
      });
    }
  }, [dispatch]);
  return (
    <>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_RECAPTHA_SITE_KEY}
        scriptProps={{
          async: false, // optional, default to false,
          defer: true, // optional, default to false
          appendTo: "body", // optional, default to "head", can be "head" or "body",
          nonce: undefined,
        }}>
        <Routes history={history}>
          <Route path="/login" element={<GuestRoute><Login/></GuestRoute>} />
          <Route path="/register" element={<GuestRoute><Register/></GuestRoute>} />
          <Route path="/private" element={<GuestRoute><Unauthenticated/></GuestRoute>} />
          <Route path="/forgot_password" element={<GuestRoute><ForgotPassword/></GuestRoute>} />

          <Route path="/" element={<MemberRoute><MyClass/></MemberRoute>} />
          <Route path="/reviews" element={<MemberRoute><MyReview/></MemberRoute>} />
          <Route path="/reviews/:id" element={<MemberRoute><Review/></MemberRoute>} />
          <Route path="/joined/:class" element={<MemberRoute><Joined/></MemberRoute>} />
          <Route path="/courses/:class/:chapter/:uid" element={<MemberRoute><DetailsClass/></MemberRoute>} />
          <Route path="/courses/:class/" element={<MemberRoute><DetailsClass/></MemberRoute>} />
          <Route path="/settings" element={<MemberRoute><Settings/></MemberRoute>} />
          <Route path="/transactions" element={<MemberRoute><Transactions/></MemberRoute>} />
          <Route path="/gift" element={<MemberRoute><MyGift/></MemberRoute>} />
          <Route path="/gift/:slug" element={<MemberRoute><Gift/></MemberRoute>} />
          
          <Route path="*" element={<NotFound/>} />
        </Routes>
      </GoogleReCaptchaProvider>
      
      <ToastContainer />
    </>
  );
}

export default App;
