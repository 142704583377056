import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import users from "constants/api/users";
import { setAuthorizationHeader } from "configs/axios";
import { populateProfile } from "store/actions/users";
import useForm from "helpers/hooks/useForm";
import Cookies from 'js-cookie';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

function LoginForm({showLoading}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [needVerification, setNeedVerification] = useState(() => false);

  const [{ email, password, verification }, setState] = useForm({
    email: "",
    password: "",
    verification: "",
  });

  const { executeRecaptcha } = useGoogleReCaptcha();

  async function submit(e) {
    e.preventDefault();

    // Check verification status
    if (needVerification){
      // Show loader
      showLoading(true);

      // Hit Verification API
      users
        .verification({ email: email, verification_code: verification })
        .then((res) => {
          // Hide loader
          showLoading(false);

          // Check response data
          if (res.status == 'success'){
            // Show success notification
            toast.success(res.message);

            // Hide verification field
            setNeedVerification(false);
          } else {
            // Show error notification
            if (res.message) toast.error(res.message);
          }
        })
        .catch((err) => {
          // Hide loader
          showLoading(false);
        });

    } else {
      // Show loader
      showLoading(true);

      // Check CAPTCHA availability
      if (!executeRecaptcha) {
        toast.error("CAPTCHA belum tersedia, coba beberapa saat lagi");

        // Hide loader
        showLoading(false);
        return;
      }

      // Check token CAPTCHA
      const token = await executeRecaptcha();
      if (!token) {
        toast.error("Error CAPTCHA, coba beberapa saat lagi");

        // Hide loader
        showLoading(false);
        return;
      }

      // Hit Login API
      users
        .login({ email, password, token })
        .then((res) => {
          // Hide loader
          showLoading(false);

          // Set auth header
          setAuthorizationHeader(res.data.token);

          // Hit User Details API
          users.details().then((detail) => {
            dispatch(populateProfile(detail.data));
           
            localStorage.setItem(
              "DEVELOBE:token",
              JSON.stringify({
                ...res.data,
                email: email,
              })
            );

            const redirect = localStorage.getItem("DEVELOBE:redirect");
            const userCookie = {
              name: detail.data.name,
              thumbnail: detail.data.avatar,
            };

            const expires = new Date(
              new Date().getTime() + 7 * 24 * 60 * 60 * 1000
            );

            // Set cookie
            Cookies.set("DEVELOBE:user", JSON.stringify(userCookie), { path: '/', expires: expires, domain: 'develobe.id' });

            // Redirect to member page
            navigate(redirect || "/");
          });
        })
        .catch((err) => {
          // Hide loader
          showLoading(false);

          // Need email verification
          if (err.response?.status == 401){
            setNeedVerification(true);
          }
        });
    }
  }

  function resendEmailVerification(){
    // Show loader
    showLoading(true);

    // Hit API
    users
      .resendVerification({ email })
      .then((res) => {
        // Hide loader
        showLoading(false);

        // Check response data
        if (res.status == 'success'){
          // Show success notification
          toast.success(res.message);

          // Navigate to login page
          navigate("/login");
        } else {
          // Show error notification
          if (res.message) toast.error(res.message);
        }
      })
      .catch((err) => {
        // Hide loader
        showLoading(false);
      });
  }

  return (
    <div className="flex justify-center items-center py-10 sm:py-20">
      <div className="w-full sm:w-4/12 bg-white rounded-lg drop-shadow-2xl p-10">
        <h1 className="text-3xl sm:text-4xl text-gray-900 mb-6">
          Lanjutkan <span className="font-bold">Konsistensimu</span><br/>Dalam Belajar
        </h1>
        <form onSubmit={submit}>
          <div className="flex flex-col mb-4">
            <label htmlFor="email" className="text-lg mb-2">
              Email
            </label>
            <input
              name="email"
              type="email"
              onChange={setState}
              className="bg-white focus:outline-none border w-full px-6 py-3 border-gray-600 focus:border-develobe-500 rounded-md"
              value={email}
              placeholder="Email"
              required={true}
            />
          </div>

          { !needVerification && (
            <div className="flex flex-col mb-4">
              <label htmlFor="password" className="text-lg mb-2">
                Kata Sandi
              </label>
              <input
                name="password"
                type="password"
                onChange={setState}
                className="bg-white focus:outline-none border w-full px-6 py-3 border-gray-600 focus:border-develobe-500 rounded-md"
                value={password}
                placeholder="Kata Sandi"
                required={true}
                minLength={6}
              />
            </div>
          )}

          { needVerification && (
            <div className="flex flex-col mb-4">
              <label htmlFor="verification" className="text-lg mb-2">
                Kode Verifikasi
              </label>
              <input
                name="verification"
                type="text"
                onChange={setState}
                className="bg-white focus:outline-none border w-full px-6 py-3 border-gray-600 focus:border-develobe-500 rounded-md uppercase"
                value={verification}
                placeholder="Kode Verifikasi"
                required={true}
              />
              
              <div className="mt-2 text-sm text-gray-500 hover:text-develobe-500 cursor-pointer duration-200" onClick={resendEmailVerification}>Kirim Ulang Kode Verifikasi</div>
            </div>
          )}

          <button
            type="submit"
            className="bg-develobe-500 hover:drop-shadow-xl transition-all duration-200 focus:outline-none shadow-inner text-white px-6 py-3 mt-4 w-full rounded-md">
            { needVerification ? "Verifikasi" : "Masuk" }
          </button>

          <div className="w-full flex justify-center mt-5">
            <Link
              to="/forgot_password"
              className="transition-all duration-200 text-gray-500 hover:text-red-500 text-md">
                Lupa Kata Sandi?
            </Link>
          </div>
        </form>
      </div>

      <div className="w-1/12 hidden sm:block mx-5"></div>

      <div className="w-4/12 hidden sm:block justify-end pt-24 pr-16">
        <div className="relative" style={{ width: 369, height: 440 }}>
          <div
            className="absolute border-develobe-500 border-2 -mt-8 -ml-16 left-0"
            style={{ width: 324, height: 374 }}
          ></div>
          <div className="absolute w-full h-full -mb-8 -ml-8">
            <img
              src="/assets/images/login.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginForm;
