import React from "react";

export default function EmptyState() {
  return (
    <section className="h-screen flex justify-center flex-col items-center relative z-10 bg-white">
      <div className="w-full sm:w-5/12 text-center py-12 mx-auto">
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/undraw_online_learning.svg`}
          alt=""
        />
        <h1 className="text-3xl text-gray-900 mt-12">Belum ada transaksi</h1>
        <p className="text-lg text-gray-600 mt-4 mb-8 mx-auto text-center">
          Ayo beli kelas premium pertamamu di DEVELOBE
        </p>
        <a
          className="bg-develobe-500 hover:drop-shadow-xl rounded-md transition-all duration-200 focus:outline-none shadow-inner text-white px-6 py-3 mt-5"
          target="_blank"
          rel="noopener noreferrer"
          href={`${process.env.REACT_APP_FRONTPAGE_URL}/courses`}>
          Cari Kelas
        </a>
      </div>
    </section>
  );
}
