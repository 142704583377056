import React from "react";
import { useNavigate } from "react-router-dom";
import users from "constants/api/users";
import useForm from "helpers/hooks/useForm";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ForgotPasswordForm({ showLoading }) {
  const navigate = useNavigate();

  const [{ email }, setState] = useForm({
    email: ""
  });

  function submit(e) {
    e.preventDefault();

    // Show loader
    showLoading(true);

    // Hit API
    users
      .forgotPassword({ email })
      .then((res) => {
        // Hide loader
        showLoading(false);

        // Check response data
        if (res.status == 'success'){
          // Show success notification
          toast.success(res.message);

          // Navigate to login page
          navigate("/login");
        } else {
          // Show error notification
          if (res.message) toast.error(res.message);
        }
      })
      .catch((err) => {
        // Hide loader
        showLoading(false);
        
      });
  }

  return (
    <div className="flex justify-center items-center py-10 sm:py-20">
      <div className="w-full sm:w-4/12 bg-white rounded-lg drop-shadow-2xl p-10">
        <h1 className="text-3xl sm:text-2xl text-gray-900 mb-6">
          Lupa Kata Sandi
        </h1>
        <form onSubmit={submit}>
          <div className="flex flex-col mb-4">
            <label htmlFor="email" className="text-lg mb-2">
              Email
            </label>
            <input
              name="email"
              type="email"
              onChange={setState}
              className="bg-white focus:outline-none border w-full px-6 py-3 border-gray-600 focus:border-develobe-500 rounded-md"
              value={email}
              placeholder="Email yang telah didaftarkan"
              required={true}
            />
          </div>

          <button
            type="submit"
            className="bg-develobe-500 hover:drop-shadow-xl transition-all duration-200 focus:outline-none shadow-inner text-white px-6 py-3 mt-4 w-full rounded-md">
            Kirim Password Baru
          </button>
        </form>
      </div>

      <div className="w-1/12 hidden sm:block mx-5"></div>

      <div className="w-4/12 hidden sm:block justify-end pt-24 pr-16">
        <div className="relative" style={{ width: 369, height: 440 }}>
          <div
            className="absolute border-develobe-500 border-2 -mt-8 -ml-16 left-0"
            style={{ width: 324, height: 374 }}
          ></div>
          <div className="absolute w-full h-full -mb-8 -ml-8">
            <img
              src="/assets/images/login.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPasswordForm;
