import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import courses from "constants/api/courses";
import ServerError from "pages/500";
import Loading from "parts/Loading";
import { useMatch } from "react-router-dom";

export default function Joined() {
  const match = useMatch('/joined/:class');
  const navigate = useNavigate();

  const [state, setstate] = useState(() => ({
    isLoading: true,
    isError: false,
    data: {},
  }));

  const joining = React.useCallback(async () => {
    try {
      const details = await courses.details(match.params.class);
      const joined = await courses.join(match.params.class);

      if (joined.data.snap_url) window.location.href = joined.data.snap_url;
      else setstate({ isLoading: false, isError: false, data: details });
    } catch (error) {
      if (error?.response?.status === 409){
        navigate(`/courses/${match.params.class}`);
      }
    }
  }, [match.params.class]);

  useEffect(() => {
    joining();
  }, [joining]);

  if (state.isLoading) return <Loading></Loading>;
  if (state.isError) return <ServerError></ServerError>;

  return (
    <section className="h-screen flex flex-col items-center mt-24">
      <img
        src={`${process.env.PUBLIC_URL}/assets/images/undraw_join.svg`}
        alt=""
      />
      <h1 className="text-3xl text-gray-900 mt-12">Selamat Bergabung di Kelas DEVELOBE</h1>
      <p className="text-lg text-gray-600 mt-4 mtb-8 lg:w-4/12 xl:w-3/12 mx-auto text-center">
        Kamu berhasil bergabung dengan kelas {" "}
        <strong>{state?.data?.name ?? "Nama Kelas"}</strong>
      </p>
      <Link
        className="cursor-pointer bg-develobe-500 hover:drop-shadow-xl transition-all duration-200 focus:outline-none shadow-inner text-white px-6 py-3 mt-5 rounded-md"
        to={`/courses/${match.params.class}`}
      >
        Start learn
      </Link>
    </section>
  );
}
