import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const GuestRoute = ({ children }) => {
  let location = useLocation();
  
  const ok = localStorage.getItem("DEVELOBE:token");
  const params = location?.search.substring(1).split("&");
  const path = params.find((item) => item.indexOf("path") > -1);
  const redirect = path?.split("=")?.[1];
  const navigate = useNavigate();

  useEffect(() => {
    if(ok) navigate(`/`);
  }, [location, ok, navigate]);

  if (ok) return null;
  
  if (redirect) localStorage.setItem("DEVELOBE:redirect", redirect);

  return children;
};

export default GuestRoute;
