import axios from "configs/axios";

export default {
  login: (credentials) => axios.post("/users/login", credentials),
  register: (payload) => axios.post("/users/register", payload),
  refresh: (credentials) =>
    axios.post("/refresh-tokens", {
      refresh_token: credentials.refresh_token,
      email: credentials.email,
    }),

  details: () => axios.get("/users"),
  update: (data) => axios.put("/users", data),
  logout: () => axios.post("/users/logout"),
  forgotPassword: (payload) => axios.post("/users/forgot_password", payload),
  resendVerification: (payload) => axios.post("/users/resend_verification", payload),
  verification: (payload) => axios.post("/users/verification", payload),
};
