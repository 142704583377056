import React from "react";
import { Link } from "react-router-dom";

export default function Congratulation({ data }) {
  return (
    <section className="h-screen flex justify-center flex-col items-center">
      <div className="w-full sm:w-5/12 text-center py-12 px-10 sm:px-0 sm:mx-auto">
        <img
          src={data?.metadata?.course_thumbnail}
          alt=""
          className="rounded-xl drop-shadow-xl border border-develobe-500 p-2"
        />
        <h1 className="text-2xl sm:text-3xl text-black font-bold mt-8">Selamat Bergabung</h1>
        <p className="text-lg text-gray-600 mt-2 mb-8 mx-auto text-center">
          Anda berhasil bergabung ke dalam kelas{" "}
          <strong className="text-black">{data?.metadata?.course_name ?? "Course Name"}</strong>
        </p>
        <Link
          className="bg-develobe-500 hover:drop-shadow-xl rounded-md transition-all duration-200 focus:outline-none shadow-inner text-white px-6 py-3 mt-5"
          to={`/courses/${data.course_id}`}>
          Mulai Belajar
        </Link>
      </div>
    </section>
  );
}
