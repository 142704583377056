import { combineReducers } from "redux";

import users from "./users";
import courses from "./courses";
import orders from "./orders";
import reviews from "./reviews";
import media from "./media";

export default combineReducers({
  users,
  courses,
  orders,
  reviews,
  media
});
