import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "parts/Sidebar";
import courses from "constants/api/courses";
import Loading from "parts/Loading";

import {
  statusCourses,
  fetchCourses,
  messageCourse,
} from "store/actions/courses";
import Header from "parts/Header";
import ListReviewItem from "parts/ListReviewItem";

function EmptyState() {
  return (
    <section className="flex h-screen items-center relative z-10 bg-white">
      <div className="w-full sm:w-8/12 text-center py-12 mx-auto">
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/undraw_online_learning.svg`}
          alt=""
          className="w-8/12 mx-auto"
        />
        <h1 className="text-3xl text-gray-900 mt-12">Belum ada review</h1>
        <p className="text-lg text-gray-600 mt-4 mb-8 mx-auto text-center">
          Ayo pilih kelas pertamamu di DEVELOBE dan berikan feedback mu untuk membantu kami meningkatkan kualitas pembelajaran
        </p>
        <a
          className="bg-develobe-500 hover:drop-shadow-xl rounded-md transition-all duration-200 focus:outline-none shadow-inner text-white px-6 py-3 mt-5"
          href={`${process.env.REACT_APP_FRONTPAGE_URL}/courses`}
        >
          Cari Kelas
        </a>
      </div>
    </section>
  );
}

export default function MyReview() {
  const dispatch = useDispatch();
  const COURSES = useSelector((state) => state.courses);

  useEffect(() => {
    window.scroll(0, 0);
    document.title = "Review Saya | DEVELOBE EDU";

    dispatch(statusCourses("loading"));
    courses
      .mine()
      .then((res) => {
        dispatch(fetchCourses(res.data));
      })
      .catch((err) => {
        dispatch(messageCourse(err?.response?.data?.message ?? "error"));
      });
  }, [dispatch]);

  return (
    <div>
      <section className="py-6 min-h-0 md:min-h-0 fixed bg-white w-full z-20 drop-shadow-sm">
        <div className="container mx-auto px-4">
          <Header onDashboard={true}/>
        </div>
      </section>
      <section className="flex">
        <div className="hidden sm:block">
          <Sidebar></Sidebar>
        </div>

        <main className="flex-1 w-full sm:w-fit px-4 sm:px-0">
          <div className="px-0 sm:px-16 mb-10 sm:mb-10">
            {COURSES.status === "loading" && <Loading></Loading>}
            {COURSES.status === "error" && COURSES.message}
            {COURSES.status === "ok" &&
              (COURSES.total > 0 ? (
                <>
                  <section className="flex flex-col pl-0 mt-24 sm:mt-32">
                    <h1 className="text-2xl sm:text-4xl text-gray-900 font-medium">
                      Review Saya
                    </h1>
                  </section>
                  <section className="flex flex-col mt-2 sm:mt-5">
                    <div className="grid grid-cols-1 gap-y-3 sm:gap-3 justify-start items-center">
                      {Object.values(COURSES.data)?.map?.((item, index) => {
                        return (
                          <div key={index}>
                            <ListReviewItem data={item}/>
                          </div>
                        );
                      })}
                    </div>
                  </section>
                </>
              ) : (
                <EmptyState></EmptyState>
              ))}
          </div>
        </main>
      </section>
    </div>
  );
}
