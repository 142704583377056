import React from "react";
import { ReactComponent as DefaultUser } from "assets/images/default-avatar.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import users from "constants/api/users";
import Cookies from 'js-cookie'

function Sidebar() {
  const navigate = useNavigate();
  const [toggleMenu, setToggleMenu] = React.useState(false);
  const params = useLocation();

  const getNavLinkClass = (path) => {
    return params?.pathname === path
      ? "active text-develobe-500 bg-develobe-50"
      : "text-black-500";
  };
  const USERS = useSelector((state) => state.users);

  function logout() {
    users.logout().then(() => {
      localStorage.removeItem("DEVELOBE:token");
      Cookies.remove('DEVELOBE:user', {path: '/', domain: 'develobe.id'});
      navigate("/login");
    });
  }

  const sidebarStyle = {
    width: 360,
    left: window.innerWidth < 640 && !toggleMenu ? "-360px" : 0,
  };

  return (
    <>
      <div className="flex sm:hidden">
        <button
          onClick={() => setToggleMenu((prev) => !prev)}
          className={["toggle z-10", toggleMenu ? "active" : ""].join(" ")}
        ></button>
      </div>
      <aside
        className="transition-all duration-300 z-10 flex h-screen justify-center items-center ml-10"
        style={sidebarStyle}
      >
        {toggleMenu && (
          <div
            className="overlay"
            onClick={() => setToggleMenu((prev) => !prev)}
          ></div>
        )}
        <div
          className="fixed bg-white flex flex-col content-between z-10 drop-shadow-xl border border-gray-100 rounded-xl py-5 px-5"
          style={{ width: 360 }}
        >
          <div className="flex flex-col text-center mt-8">
            <div className="border border-develobe-500 rounded-full mx-auto p-2 inline-flex  mb-3">
              <div className="rounded-full overflow-hidden">
                {USERS?.avatar ? (
                  <img
                    className="object-cover w-40 h-40"
                    src={USERS?.avatar}
                    alt={USERS?.name}
                  />
                ) : (
                  <DefaultUser className="fill-develobe-500 w-24 h-24"></DefaultUser>
                )}
              </div>
            </div>

            <h6 className="text-black text-xl">{USERS?.name ?? "Username"}</h6>
            <span className="text-black-500 text-sm">
              {USERS?.profession ?? "Profession"}
            </span>
          </div>

          <ul className="main-menu mt-12">
            <li>
              <Link
                className={[
                  "nav-link rounded-md relative flex items-center py-3 px-5 transition-all duration-200 hover:text-develobe-500 w-full text-left",
                  getNavLinkClass("/"),
                ].join(" ")}
                to="/"
              >
                Kelas Saya
              </Link>
            </li>
            <li>
              <Link
                className={[
                  "nav-link rounded-md relative flex items-center py-3 px-5 transition-all duration-200 hover:text-develobe-500 w-full text-left",
                  getNavLinkClass("/reviews"),
                ].join(" ")}
                to="/reviews"
              >
                Review Saya
              </Link>
            </li>
            <li>
              <Link
                className={[
                  "nav-link rounded-md relative flex items-center py-3 px-5 transition-all duration-200 hover:text-develobe-500 w-full text-left",
                  getNavLinkClass("/transactions"),
                ].join(" ")}
                to="/transactions"
              >
                Transaksi
              </Link>
            </li>
            
            <li>
              <Link
                className={[
                  "nav-link rounded-md relative flex items-center py-3 px-5 transition-all duration-200 hover:text-develobe-500 w-full text-left",
                  getNavLinkClass("/gift"),
                ].join(" ")}
                to="/gift"
              >
                Gift
              </Link>
            </li>
 
            <li>
              <Link
                className={[
                  "nav-link rounded-md relative flex items-center py-3 px-5 transition-all duration-200 hover:text-develobe-500 w-full text-left",
                  getNavLinkClass("/settings"),
                ].join(" ")}
                to="/settings"
              >
                Pengaturan
              </Link>
            </li>
            <li>
              <button
                className={[
                  "nav-link rounded-md relative text-black-500 flex items-center py-3 px-5 transition-all duration-200 hover:text-develobe-500 w-full text-left",
                ].join(" ")}
                onClick={logout}>
                Keluar
              </button>
            </li>
          </ul>
        </div>
      </aside>
    </>
  );
}

export default Sidebar;
