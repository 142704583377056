import { 
  POPULATE_REVIEW, 
  STATUS_REVIEW 
} from "constants/types/reviews";

export const populateReview = (review = {}) => ({
  type: POPULATE_REVIEW,
  payload: review,
});

export const statusReview = (status) => ({
  type: STATUS_REVIEW,
  payload: status,
});
