import React from "react";

import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <section className="h-screen flex flex-col items-center pt-10">
      <img
        src={`${process.env.PUBLIC_URL}/assets/images/undraw_page_not_found.svg`}
        className="max-h-80"
      />
      <h1 className="text-3xl text-gray-900 mt-12">Page not found</h1>
      <p className="text-lg text-gray-600 mt-4 mtb-8 lg:w-3/12 xl:w-3/12 mx-auto text-center">
        Halaman yang Anda cari tidak ditemukan
      </p>
      <Link
        className="bg-develobe-500 hover:drop-shadow-xl rounded-md transition-all duration-200 focus:outline-none shadow-inner text-white px-6 py-3 mt-5"
        to="/"
      >
        Kembali ke home
      </Link>
    </section>
  );
}
