import { 
  POPULATE_GIFT, 
  STATUS_GIFT 
} from "constants/types/media";

export const populateGift = (gift = {}) => ({
  type: POPULATE_GIFT,
  payload: gift,
});

export const statusGift = (status) => ({
  type: STATUS_GIFT,
  payload: status,
});
